import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
import googleLogo from '../../google-logo.webp'; // Importer Google-logoen

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/booking');
    } catch (error) {
      toast.error("Innlogging feilet. Sjekk e-posten og passordet ditt.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/booking');
    } catch (error) {
      toast.error("Google-innlogging feilet. Prøv igjen.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  return (
    <div className="container login-container">
      <div className="login-content">
        <ToastContainer />
        <h2>Logg inn</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-post"
          className="input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Passord"
          className="input"
        />
        <button onClick={handleLogin}>Logg inn</button>
        <p className="or-text">eller</p>
        <button onClick={handleGoogleLogin} className="google-login-button">
          <img src={googleLogo} alt="Google logo" className="google-logo" />
          Logg inn med Google
        </button>
      </div>
    </div>
  );
};

export default Login;