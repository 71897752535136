import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase';
import { collection, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Admin.css';

const Admin = () => {
  const [reservations, setReservations] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [parkingName, setParkingName] = useState(""); // State for ny parkeringsplassnavn
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user.uid);
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.role === "admin") {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
              navigate("/login");
            }
          } else {
            console.log("User document does not exist");
            navigate("/login");
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (isAdmin) {
      const fetchReservations = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "reservations"));
          setReservations(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
          console.error("Error fetching reservations:", error);
        }
      };
      fetchReservations();
    }
  }, [isAdmin]);

  const handleAddParkingSpace = async () => {
    if (!parkingName) return;
    try {
      await addDoc(collection(db, "parkingSpaces"), {
        name: parkingName,
      });
      setParkingName(""); // Tømmer inputfeltet
      alert("Parking space added successfully!");
    } catch (error) {
      console.error("Error adding parking space:", error);
    }
  };

  if (!isAdmin) return null;

  return (
    <div className="container admin-container">
      <h2>Reservations</h2>
      <ul className="reservation-list">
        {reservations.map((reservation) => (
          <li key={reservation.id} className="reservation-item">
            <div><strong>Space ID:</strong> {reservation.spaceId}</div>
            <div><strong>User ID:</strong> {reservation.userId}</div>
            <div><strong>Date:</strong> {reservation.date}</div>
          </li>
        ))}
      </ul>

      <h2>Add New Parking Space</h2>
      <div className="add-parking">
        <input
          type="text"
          value={parkingName}
          onChange={(e) => setParkingName(e.target.value)}
          placeholder="Enter parking space name"
          className="input"
        />
        <button onClick={handleAddParkingSpace}>Add Parking Space</button>
      </div>
    </div>
  );
};

export default Admin;