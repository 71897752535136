import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import Login from './components/Login/Login';
import Booking from './components/Booking/Booking';
import Admin from './components/Admin/Admin';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  // Denne `useEffect` håndterer bare påloggingsstatusen
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        // Etter at bruker er satt, håndter Firestore-rollen i en separat `useEffect`
        navigate('/booking');
      } else {
        setUser(null);
        setIsAdmin(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Denne `useEffect` håndterer Firestore-kallene for å sjekke admin-rollen
  useEffect(() => {
    // Bare kjør denne hvis `user` er satt
    if (user) {
      const checkAdminRole = async () => {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists() && userDoc.data().role === 'admin') {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Feil ved henting av brukerrolle:", error);
        }
      };
      checkAdminRole();
    }
  }, [user]); // Kjør bare når `user` er satt

  // Funksjon for å logge ut brukeren
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsAdmin(false);
      window.location.reload();
    } catch (error) {
      console.error("Feil ved utlogging:", error);
    }
  };

  return (
    <div>
      <h1>Innoventus parkering</h1>
      <nav className="navbar">
        {user && <Link to="/booking">Booking</Link>}
        {isAdmin && <Link to="/admin">Admin</Link>}
        {user && <button onClick={handleLogout} className="logout-button">Logg ut</button>}
      </nav>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/booking" 
          element={
            <ProtectedRoute>
              <Booking />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </div>
  );
}

export default App;
