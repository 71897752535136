import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCzZvnehQjfzkdD8UIwFMdwyjeTtedZBtc",
  authDomain: "innoventus-sor-parkering.firebaseapp.com",
  projectId: "innoventus-sor-parkering",
  storageBucket: "innoventus-sor-parkering.firebasestorage.app",
  messagingSenderId: "749658140430",
  appId: "1:749658140430:web:c7c8115de87365d702315f"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };