import React, { useEffect, useState, useMemo } from 'react';
import { db, auth } from '../../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import './Booking.css';

const Booking = () => {
  const [spaces, setSpaces] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  // Funksjoner for å åpne/lukke modal
  const handleOpenModal = (spaceId) => {
    setSelectedSpace(spaceId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSpace(null);
    setStartHour("");
    setEndHour("");
  };

  // Hent tilgjengelige parkeringsplasser
  useEffect(() => {
    const fetchSpaces = async () => {
      const querySnapshot = await getDocs(collection(db, "parkingSpaces"));
      const sortedSpaces = querySnapshot.docs
        .map(doc => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setSpaces(sortedSpaces);
    };
    fetchSpaces();

    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  // Hent reservasjoner for valgt dato
  useEffect(() => {
    const fetchReservations = async () => {
      setLoading(true);
      const dateString = selectedDate.toISOString().split('T')[0];
      const q = query(collection(db, "reservations"), where("date", "==", dateString));

      const querySnapshot = await getDocs(q);
      setReservations(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      setLoading(false);
    };

    fetchReservations();
  }, [selectedDate]);

  // Hjelpefunksjoner
  const getAvailableHours = (spaceId) => {
    const spaceReservations = reservations.filter(reservation => reservation.spaceId === spaceId);
    return Array.from({ length: 12 }, (_, i) => i + 8).filter(hour =>
      spaceReservations.every(reservation => {
        const reservationFrom = parseInt(reservation.fromTime.split(":")[0]);
        const reservationTo = parseInt(reservation.toTime.split(":")[0]);
        return hour < reservationFrom || hour >= reservationTo;
      })
    );
  };

  const handleBooking = async () => {
    if (!user) {
      toast.error("Vennligst logg inn for å reservere en plass.");
      return;
    }

    if (!startHour || !endHour || parseInt(endHour) <= parseInt(startHour)) {
      toast.error("Ugyldig start- eller sluttid.");
      return;
    }

    const dateString = selectedDate.toISOString().split('T')[0];
    const fromTime = `${startHour}:00`;
    const toTime = `${endHour}:00`;

    const spaceReservations = reservations.filter(reservation => reservation.spaceId === selectedSpace);
    const isTimeSlotAvailable = spaceReservations.every(reservation => {
      const reservationFrom = parseInt(reservation.fromTime.split(":")[0]);
      const reservationTo = parseInt(reservation.toTime.split(":")[0]);
      return parseInt(toTime.split(":")[0]) <= reservationFrom || parseInt(fromTime.split(":")[0]) >= reservationTo;
    });

    if (!isTimeSlotAvailable) {
      toast.error("Valgt tidsrom overlapper med en eksisterende reservasjon.");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "reservations"), {
        spaceId: selectedSpace,
        userId: user.uid,
        email: user.email,
        date: dateString,
        fromTime,
        toTime,
      });

      setReservations(prev => [...prev, {
        id: docRef.id,
        spaceId: selectedSpace,
        userId: user.uid,
        email: user.email,
        date: dateString,
        fromTime,
        toTime,
      }]);
      toast.success("Reservasjon vellykket!");
      handleCloseModal();
    } catch (error) {
      console.error("Error creating reservation:", error);
      toast.error("Kunne ikke opprette reservasjonen. Prøv igjen.");
    }
  };

  const handleDeleteReservation = async (reservationId) => {
    const confirmDelete = window.confirm("Er du sikker på at du vil slette denne reservasjonen?");
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, "reservations", reservationId));
      setReservations(prev => prev.filter(reservation => reservation.id !== reservationId));
      toast.success("Reservasjonen ble slettet.");
    } catch (error) {
      console.error("Error deleting reservation:", error);
      toast.error("Kunne ikke slette reservasjonen. Prøv igjen.");
    }
  };

  // Memoized tilgjengelige timer for alle plasser
  const availableHoursBySpace = useMemo(() => {
    return spaces.reduce((acc, space) => {
      acc[space.id] = getAvailableHours(space.id);
      return acc;
    }, {});
  }, [reservations, spaces]);

  return (
    <div className="container booking-container">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} closeOnClick pauseOnHover draggable />
      <h2>Tilgjengelige parkeringsplasser</h2>

      <div className="date-picker">
        <label>Velg en dato:</label>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd.MM.yyyy"
          className="input"
        />
      </div>

      <div className="space-list">
        {loading ? (
          <p>Laster tilgjengelige plasser...</p>
        ) : (
          spaces.map(space => (
            <div key={space.id} className="space-item">
              <div className="space-header">
                <h3>{space.name}</h3>
                <button onClick={() => handleOpenModal(space.id)} className="reserve-button">
                  Reserver
                </button>
              </div>
              {reservations.filter(res => res.spaceId === space.id).length > 0 && (
                <div className="reservations-list">
                  <p>Reserverte tider:</p>
                  <ul>
                    {reservations.filter(res => res.spaceId === space.id).map((res, index) => (
                      <li key={index} className="reservation-item">
                        <span>{res.fromTime} - {res.toTime}</span>
                        <span className="reservation-email">{res.email}</span>
                        {res.userId === user?.uid && (
                          <FaTrashAlt
                            className="delete-icon"
                            onClick={() => handleDeleteReservation(res.id)}
                            title="Slett reservasjon"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Reserver parkeringsplass</h3>
            <div>
              <label>Starttid:</label>
              <select
                value={startHour}
                onChange={(e) => setStartHour(e.target.value)}
                className="dropdown"
              >
                <option value="">Velg starttid</option>
                {availableHoursBySpace[selectedSpace]?.map(hour => (
                  <option key={hour} value={hour}>
                    {hour}:00
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Sluttid:</label>
              <select
                value={endHour}
                onChange={(e) => setEndHour(e.target.value)}
                className="dropdown"
                disabled={!startHour}
              >
                <option value="">Velg sluttid</option>
                {availableHoursBySpace[selectedSpace]?.filter(hour => hour > parseInt(startHour)).map(hour => (
                  <option key={hour} value={hour}>
                    {hour}:00
                  </option>
                ))}
              </select>
            </div>
            <button onClick={handleBooking}>Bekreft</button>
            <button onClick={handleCloseModal}>Avbryt</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Booking;